import React, { FunctionComponent, useEffect, useState } from 'react';
import { Menu } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { difference } from 'lodash';
import { Dropdown } from '../../Common/Dropdown/Dropdown';
import { Color } from '../../../typings/backend-types';
import { LabeledInputWrapper } from './LabeledInputWrapper';
import { classNames } from 'utilities/classNames';

interface Props {
  label: string;
  formName?: string;
  required?: boolean;
  value?: string;
  small?: boolean;
  onChange?: (value: Color) => void;
  className?: string;
  helpText?: string;
  variant?: number;
}

const extraGreys = [Color.Slate, Color.Zinc, Color.Neutral, Color.Stone];

const colorOptionsWithOneGrey = difference(Object.values(Color), extraGreys);

/**
 * a basic text input with the label on the left
 * from the tailwind form layout https://tailwindui.com/components/application-ui/forms/form-layouts#component-cc5bee94bc88387c2511b0e5c3dfffef
 */
export const ColorPickerInput: FunctionComponent<Props> = ({
  label,
  formName,
  required,
  value,
  small,
  onChange,
  className,
  helpText,
  variant = 200,
}) => {
  const [inputValue, setInputValue] = useState<string>(value?.trim() ?? '');

  useEffect(() => {
    setInputValue(value ?? '');
  }, [value]);

  return (
    <LabeledInputWrapper
      label={label}
      small={small}
      className={className}
      required={required}
      helpText={helpText}
    >
      <input
        name={formName}
        id={formName}
        required={required}
        value={inputValue}
        readOnly
        className="hidden"
      />
      <Dropdown
        align={small ? 'right' : 'left'}
        button={
          <div
            className={classNames(
              'border flex items-center justify-between space-x-2 py-1 px-2 focus:ring-accent-500 focus:border-accent-500 text-sm border-gray-300 rounded-lg',
              !small && ' max-w-lg sm:max-w-xs',
            )}
          >
            <span
              className={classNames(
                'rounded-full w-7 h-7 inline-block',
                !inputValue && 'border border-1',
                `bg-${inputValue}-${variant}`,
              )}
            />
            <ChevronDownIcon className="w-4 h-4 text-gray-500" />
          </div>
        }
      >
        <Menu.Item>
          <div className="flex items-center justify-center p-3">
            <div className="grid grid-cols-5 gap-2">
              {colorOptionsWithOneGrey.map((c) => (
                <div
                  key={c}
                  className={classNames(
                    'w-7 h-7 rounded-full cursor-pointer',
                    `bg-${c}-${variant} hover:bg-${c}-${variant + 100}`,
                  )}
                  onClick={() => {
                    onChange?.(c);
                    setInputValue(c);
                  }}
                />
              ))}
            </div>
          </div>
        </Menu.Item>
      </Dropdown>
    </LabeledInputWrapper>
  );
};

ColorPickerInput.defaultProps = {
  required: false,
  value: '',
};
